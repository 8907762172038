import Vue from 'vue';
import VueRouter from 'vue-router';

//路由懒加载
const Login = () => import('../views/login/Login');
//const Home = () => import('../views/home/Home');

const LoginHome = resolve => require(["@/views/loginHome/LoginHome"], resolve);
const CourseDetail = () => import("../views/courseDetail/CourseDetail");
const CasePresentation = () => import("../views/news/CasePresentation");
const NewsDetail = () => import("../views/newsDetail/NewsDetail");
const TeachingDetail = () => import("../views/newsDetail/TeachingDetail");//各地教研详情
const CoursePreview = () => import("../views/courseDetail/CoursePreview");//播放ppt，暂时舍弃
const CoursePlayOffice = () => import("../views/courseDetail/CoursePlayOffice");//第三方office播放界面
const Psychometrics = () => import("../views/news/Psychometrics");
const SchoolCollaboration = () => import("../views/news/SchoolCollaboration");
const TeachingResearch = () => import("../views/news/TeachingResearch");
const VideoDetail = () => import("../views/newsDetail/VideoDetail");
const TeachingMore = () => import("../views/news/TeachingMore");
const EducationMore = () => import("../views/news/EducationMore");
const TeacherMore = () => import("../views/news/TeacherMore");
const AboutUs = () => import("../views/newsDetail/AboutUs");
const SchoolCourseDuration = () => import("../views/dataDetail/SchoolCourseDuration");
const TeacherCourseDuration = () => import("../views/dataDetail/TeacherCourseDuration");
const maxscreen = () => import("../views/index");

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		component: LoginHome,
		hidden: true
	}, {
		path: '/login',
		name: '/login',
		component: Login
	},
	// {
	// 	path: '/home',
	// 	name: '/home',
	// 	component: Home
	// }, 
	{
		path: '/loginHome',
		name: '/loginHome',
		component: LoginHome,
		// meta: {
		// 	requireAuth: true, // 判断是否需要登录
		// },
	},
	{
		path: '/courseDetail',
		name: '/courseDetail',
		component: CourseDetail
	}, {
		path: '/newsDetail/:id',
		name: '/newsDetail',
		component: NewsDetail
	},
	{
		path: '/coursePreview',
		name: '/coursePreview',
		component: CoursePreview
	}, {
		path: '/coursePlayOffice',
		name: '/coursePlayOffice',
		component: CoursePlayOffice
	}, {
		path: '/psychometrics',
		name: '/psychometrics',
		component: Psychometrics //心理测评
	}, {
		path: '/schoolCollaboration',
		name: '/schoolCollaboration',
		component: SchoolCollaboration //家校协同
	}, {
		path: '/teachingResearch',
		name: '/teachingResearch',
		component: TeachingResearch //教学教研
	},
	{
		path: '/videoDetail/:id',
		name: '/videoDetail',
		component: VideoDetail //名师讲堂详情
	},
	{
		path: '/teachingMore',
		name: '/teachingMore',
		component: TeachingMore //各地教研更多
	}, {
		path: '/teachingDetail/:id/:mdd',
		name: '/teachingDetail',
		component: TeachingDetail//各地教研详情
	},
	{
		path: '/educationMore',
		name: '/educationMore',
		component: EducationMore //教育大视野更多
	},
	{
		path: '/teacherMore',
		name: '/teacherMore',
		component: TeacherMore //名师讲堂更多
	},
	{
		path: '/CasePresentation',
		name: '/CasePresentation',
		component: CasePresentation //案例展示
	}, {
		path: '/aboutUs',
		name: '/aboutUs',
		component: AboutUs//关于我们
	}, {
		path: '/SchoolCourseDuration',
		name: '/SchoolCourseDuration',
		component: SchoolCourseDuration//学校数据分析
	}, {
		path: '/TeacherCourseDuration',
		name: '/TeacherCourseDuration',
		component: TeacherCourseDuration//教师数据分析
	}, {
		path: '/maxscreen',
		name: '/maxscreen',
		component: maxscreen//大屏
	}
]

const router = new VueRouter({
	routes,
	mode: 'history'
})

export default router