import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/common.css';
import App from './App.vue';
import router from './router'
import common from './common/Global'
import BaiduMap from 'vue-baidu-map'

import dataV from '@jiaminghi/data-view'; 
Vue.use(dataV);

import global from './utils/global' // 全局方法
Vue.use(global)

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;
import 'echarts/map/js/china.js'

import 'vant/lib/index.css';
import {
	Button,
	DropdownMenu,
	DropdownItem,
	Cell,
	Switch,
	Field
} from 'vant'
import {
	RadioGroup,
	Radio
} from 'vant';
import {
	CheckboxGroup,
	Checkbox
} from 'vant';
import {
	Tab,
	Tabs
} from 'vant';
import {
	Tabbar,
	TabbarItem
} from 'vant';
import {
	NavBar,
	Icon,
	Tag
} from 'vant';
Vue.component('van-field', Field)
Vue.component('van-button', Button)
Vue.component('van-dropdown-menu', DropdownMenu)
Vue.component('van-dropdown-item', DropdownItem)
Vue.component('van-cell', Cell)
Vue.component('van-switch', Switch)
Vue.component('van-checkbox-group', CheckboxGroup)
Vue.component('van-checkbox', Checkbox)
Vue.component('van-radio-group', RadioGroup)
Vue.component('van-radio', Radio)
Vue.component('van-tab', Tab)
Vue.component('van-tabs', Tabs)
Vue.component('van-tabbar', Tabbar)
Vue.component('van-tabbar-item', TabbarItem)
Vue.component('van-nav-bar', NavBar)
Vue.component('van-icon', Icon)
Vue.component('van-tag', Tag)
Vue.config.productionTip = false
Vue.prototype.common = common

Vue.use(ElementUI, {
	size: 'small',
	zIndex: 3000
});

Vue.use(BaiduMap, {
	ak: '33B192o1jPaqOHASGGAIkoMuwi8W76j3'  //这个地方是官方提供的ak密钥
  })

// 路由判断登录 根据路由配置文件的参数
router.beforeEach((to, from, next) => {
	let title = '心理健康课程大数据平台'
	if (to.meta.title) {
		title += '_' + to.meta.title
	}
	document.title = title;

	if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
		console.log('需要登录');
		if (getToken()) { // 判断当前的token是否存在 ； 登录存入的token
			next();
		} else {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				} // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})
		}
	} else {
		next();
	}
});

new Vue({
	el: '#app',
	router,
	render: h => h(App)
});